import store from '@/store'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'

export default function antiFraudJs() {
  const MERCHANT_STORE_MODULE_NAME = 'merchant'

  // Register module
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)

  const fetchApps = (success, fail) => {
    store
      .dispatch('merchant/fetchApps')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateMockWebsiteUrl = (params, success, fail) => {
    store
      .dispatch('merchant/updateMockWebsiteUrl', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateMockAppName = (params, success, fail) => {
    store
      .dispatch('merchant/updateMockAppName', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchSanctions = (params, success, fail) => {
    store
      .dispatch('merchant/fetchSanctions', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const getTongdunToken = (success, fail) => {
    store
      .dispatch('merchant/getTongdunToken')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchTraining = (params, success, fail) => {
    store
      .dispatch('merchant/fetchTraining', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const saveTraining = (params, success, fail) => {
    store
      .dispatch('merchant/saveTraining', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const uploadTraining = (params, success, fail) => {
    store
      .dispatch('merchant/uploadTraining', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  return {
    fetchApps,
    updateMockWebsiteUrl,
    updateMockAppName,
    fetchSanctions,
    getTongdunToken,
    fetchTraining,
    saveTraining,
    uploadTraining,
  }
}
