<template>
  <div>
    <b-card title="Training Record">
      <b-row>
        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-button
            variant="primary"
            @click="add"
          >
            Add
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-table
        class="training-view-table"
        responsive
        :fields="TrainingFields"
        :items="trainings"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching trainings found"
      >
        <template #cell(train_date)="data">
          {{ data.item.train_date.split(' ')[0] }}
        </template>
        <template #cell(id)="data">
          <b-button
            variant="outline-primary"
            pill
            @click="edit(data.item)"
          >
            Edit
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePageNum"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="train-modal"
      size="lg"
      :title="trainModalTitle"
      ok-only
      ok-title="Confirm"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="trainRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Date"
                label-for="DateInput"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  rules="required"
                >
                  <date-picker id="DateInput"
                               v-model="train.date"
                               class="train-date-input"
                               type="date"
                               placeholder="Date"
                               value-type="format"
                               :state="errors.length > 0 ? false:null"
                  />
                  <p>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </p>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Trainer"
                label-for="TrainerInput"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Trainer"
                  rules="required"
                >
                  <b-form-input
                    id="TrainerInput"
                    v-model="train.trainer"
                    placeholder="Trainer"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Subject"
                label-for="SubjectInput"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Subject"
                  rules="required"
                >
                  <b-form-input
                    id="SubjectInput"
                    v-model="train.subject"
                    placeholder="Subject"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Attendee"
                label-for="AttendeeInput"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Attendee"
                  rules="required"
                >
                  <b-form-input
                    id="AttendeeInput"
                    v-model="train.attendee"
                    placeholder="Attendee"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Content"
            label-for="ContentInput"
          >
            <validation-provider
              #default="{ errors }"
              name="Content"
              rules="required"
            >
              <b-form-textarea
                id="ContentInput"
                v-model="train.content"
                placeholder="Content"
                rows="5"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Upload"
            label-for="app-website-img"
          >
            <b-form-file
              ref="file-input"
              v-model="train.uploadImages"
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
              multiple
              input-id="app-website-img"
              @change="changeFile"
            />
          </b-form-group>
          <div>
            <ul class="train-app-list">
              <li v-for="(item, index) in trainFileArray" :key="index">
                <a :href="item.url" target="_blank" rel="noopener noreferrer">
                  <img :src="fileType(item.type)" alt="" class="app-list-image">
                  <span class="app-list-text">{{ item.name }}</span>

                </a>
                <feather-icon
                  icon="Trash2Icon"
                  size="20"
                  class="mr-50 delete-icon"
                  @click="deleteFile(index)"
                />
              </li>
            </ul>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCard, BRow, BCol, BTable, BForm, BFormGroup, BPagination, BFormInput, BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import { required } from '@validations'
import { showToast } from '@/libs/tool'
import useAntiFraudJs from './anti-fraud'
import 'vue2-datepicker/index.css'

const {
  fetchTraining,
  saveTraining,
  uploadTraining,
} = useAntiFraudJs()

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BForm,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormTextarea,
    DatePicker,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      trainModalTitle: '',
      train: {
        date: '',
        trainer: '',
        subject: '',
        content: '',
        attendee: '',
        uploadImages: [],
      },
      currentId: null,
      trainings: [],
      TrainingFields: [
        { key: 'train_date', label: 'Date' },
        { key: 'trainer', label: 'Trainer' },
        { key: 'train_subject', label: 'Subject' },
        { key: 'train_content', label: 'Content' },
        { key: 'attendee', label: 'Attendee' },
        { key: 'create_time', label: 'Create Time' },
        { key: 'id', label: 'operation' },
      ],
      pageNum: 1,
      pageSize: 10,
      currentPage: 1,
      total: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      trainFileArray: [],
      // eslint-disable-next-line global-require
      docPng: require('@/assets/images/filetype/doc.png'),
      // eslint-disable-next-line global-require
      docxPng: require('@/assets/images/filetype/docx.png'),
      // eslint-disable-next-line global-require
      emptyFilePng: require('@/assets/images/filetype/empty-file.png'),
      // eslint-disable-next-line global-require
      jpegPng: require('@/assets/images/filetype/jpeg.png'),
      // eslint-disable-next-line global-require
      jpgPng: require('@/assets/images/filetype/jpg.png'),
      // eslint-disable-next-line global-require
      pdfPng: require('@/assets/images/filetype/pdf.png'),
      // eslint-disable-next-line global-require
      pngPng: require('@/assets/images/filetype/png.png'),
      // eslint-disable-next-line global-require
      xlsPng: require('@/assets/images/filetype/xls.png'),
      // eslint-disable-next-line global-require
      xlsxPng: require('@/assets/images/filetype/xlsx.png'),
    }
  },
  created() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.trainings = []
      const params = {
        page_num: this.pageNum,
        page_size: this.pageSize,
      }
      fetchTraining(
        params,
        trainings => {
          this.trainings = trainings.list || []
          this.total = trainings.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.total - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.total
          this.dataMeta.of = this.total
        },
        fail => {
          showToast(this, 'Warning', `Fetch Training with ${fail}`)
        },
      )
    },
    changePageNum(value) {
      this.pageNum = value
      this.refresh()
    },
    add() {
      this.currentId = null
      this.trainModalTitle = 'Add'
      this.$refs['train-modal'].show()
    },
    edit(item) {
      this.currentId = item.id
      this.trainModalTitle = 'Edit'
      this.$refs['train-modal'].show()
      this.renderTrain(item)
    },
    resetModal() {
      this.train = {
        date: '',
        trainer: '',
        subject: '',
        content: '',
        attendee: '',
        uploadImages: [],
      }
      this.trainFileArray = []
    },
    handleOk(event) {
      event.preventDefault()
      const params = {
        attendee: this.train.attendee,
        train_content: this.train.content,
        train_date: `${this.train.date} 00:00:00`,
        train_subject: this.train.subject,
        trainer: this.train.trainer,
        uploads: this.trainFileArray ? this.trainFileArray.map(value => value.url) : [],
      }
      if (typeof this.currentId !== 'object') {
        params.id = this.currentId
      }
      const that = this
      this.$refs.trainRules.validate().then(success => {
        if (success) {
          that.$showLoading()
          saveTraining(
            params,
            () => {
              showToast(that, 'Success', 'Success')
              that.$refs['train-modal'].hide()
              that.pageNum = 1
              that.refresh()
              that.$hideLoading()
            },
            fail => {
              showToast(this, 'Warning', `save Training with ${fail}`)
              that.$hideLoading()
            },
          )
        }
      })
    },
    renderTrain(data) {
      if (!data) return
      this.train = {
        date: data.train_date.split(' ')[0],
        trainer: data.trainer,
        subject: data.train_subject,
        content: data.train_content,
        attendee: data.attendee,
        uploadImages: [],
      }
      this.trainFileArray = this.getFileObject(data.uploads) || []
    },
    changeFile(event) {
      const fileList = event.target.files
      this.uploadFile(fileList)
    },
    uploadFile(fileList) {
      if (!fileList || fileList.length <= 0) {
        showToast(this, 'Warning', 'No File Select')
        return
      }
      const params = new FormData()
      fileList.forEach(file => {
        params.append('files', file)
      })
      const that = this
      that.$showLoading()
      uploadTraining(
        params,
        res => {
          that.$hideLoading()
          const { urlList } = res
          that.trainFileArray = that.getFileObject(urlList).concat(that.trainFileArray)
          that.unique(that.kycFilesArray)
          that.$refs['file-input'].reset()
          showToast(that, 'Success', 'Success')
        },
        fail => {
          that.$hideLoading()
          showToast(this, 'Warning', `Upload proofs fail with ${fail}`)
        },

      )
    },
    getFileObject(list) {
      if (list.length <= 0) return []
      const fileArray = []
      list.forEach(value => {
        const file = {}
        const nameIndex = value.lastIndexOf('/') + 1
        const typeIndex = value.lastIndexOf('.') + 1
        file.name = value.substring(nameIndex)
        file.url = value
        file.type = value.substring(typeIndex)
        fileArray.push(file)
      })
      // eslint-disable-next-line consistent-return
      return fileArray
    },
    unique(arr) {
      if (!Array.isArray(arr)) {
        // console.log('type error!')
        return
      }
      const urls = []
      const array = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        if (urls.indexOf(arr[i].url) === -1) {
          urls.push(arr[i].url)
          array.push(arr[i])
        }
      }
      // eslint-disable-next-line consistent-return
      return array
    },
    deleteFile(index) {
      this.trainFileArray.splice(index, 1)
    },
    fileType(type) {
      if (type === 'doc') {
        return this.docPng
      } if (type === 'docx') {
        return this.docxPng
      } if (type === 'jpeg' || type === 'jpe') {
        return this.jpegPng
      } if (type === 'jpg') {
        return this.jpgPng
      } if (type === 'pdf') {
        return this.pdfPng
      } if (type === 'png') {
        return this.pngPng
      } if (type === 'xls') {
        return this.xlsPng
      } if (type === 'xlsx') {
        return this.xlsxPng
      }
      return this.emptyFilePng
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.train-app-list {
  padding: 0;
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    border-bottom: 1px solid #DCDFE6;
    padding-bottom: 6px;
    margin-bottom: 10px;
    a {
      color: #333;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .delete-icon {
      cursor: pointer;
    }
    .app-list-image {
      width: 22px;
      height: auto;
      align-self: flex-start;
    }
    .app-list-text {
      margin-left: 10px;
    }
  }
}
.train-date-input {
  width: 100%;
  input {
    padding: 0.438rem 1rem;
    height: 2.714rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
  }
  input::-webkit-input-placeholder{
    color:#d8d6de;
  }
}
.training-view-table {
  tbody tr {
    td {
      word-break: break-all;
    }
    td:nth-of-type(1) {
      white-space: nowrap;
    }
  }
}
</style>
